import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Referenzen } from "../src/gatsby-theme-docz/components/Referenzen/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "referenzen"
    }}>{`Referenzen`}</h1>
    <p>{`Gemäß unseren Devisen "Alles aus einer Hand" und "Qualität zu fairen Preisen" werden wir
bei unseren Kunden tätig. Dabei tragen die Gewerke Heizung und Sanitär den Hauptanteil am Jahresumsatz. Unsere Arbeitsgebiete sind der Industrie-, der Gesellschafts-, Wohnungs- und Eigenheimbau. Im Bereich der komplexen Wohnungssanierung im bewohnten Zustand haben wir bei ca. 2100 WE unser Können bei den Wohnungsbaugesellschaften unter Beweis gestellt. Schwerpunkt unserer Arbeiten ist seit mehreren Jahren der Krankenhausbau und der Bau von Pflegeeinrichtungen. Hier können unsere Bauleiter und Mitarbeiter auf eine sehr große Kompetenz verweisen.`}</p>
    <Referenzen mdxType="Referenzen" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      